import React, { Component } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Placeholder from "../components/placeholder"
import Layout from "../layouts"
import Seo from "../components/seo"
import Header from "../components/header"
import PageTitle from "../components/pageTitle"
import PageIntro from "../components/pageIntro"
import DownloadCog from "../components/downloadCog"
import Features from "../components/features"

class PropertiesTemplate extends Component {
  render() {
    const currentPage = this.props.data.allWpPage.edges[0].node,
      pageId =
        currentPage.parentId === 0 ? currentPage.id : currentPage.parentId,
      pageTitle = currentPage.parentId === 0 ? currentPage.title : false,
      introTitle =
        currentPage.introduction !== null
          ? currentPage.introduction.introTitle
          : "",
      introDesc =
        currentPage.introduction !== null ? currentPage.introduction.intro : "",
      properties =
        currentPage.properties !== null
          ? currentPage.properties.properties
          : "",
      seoTitle = currentPage.title,
      seoDesc = ""

    return (
      <Layout>
        <div className="page">
          <Seo seoTitle={seoTitle} seoDesc={seoDesc} />
          <Header />
          <PageTitle pageId={pageId} pageTitle={pageTitle} />
          <PageIntro introTitle={introTitle} introDesc={introDesc} />

          <section className="section sectionWhite">
            <div className="containerFluid">
              <div className="row">
                <div className="col-bp1-12">
                  <div className="entry">
                    <div
                      dangerouslySetInnerHTML={{ __html: currentPage.content }}
                    ></div>
                  </div>

                  <br />

                  <div className="row">
                    {properties.map((property, i) => {
                      const url =
                        property.propertyFile !== null &&
                        property.propertyFile !== false
                          ? property.propertyFile.localFile.url
                          : ""
                      return (
                        <div key={i} className="col-bp1-12 col-bp2-6 col-bp5-4">
                          <div className="block">
                            {featuredImage(property.propertyImage, url)}
                            <div className="block__content block__content--properties">
                              <h3 className="block__title">
                                <a href={url}>{property.propertyTitle}</a>
                              </h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: property.propertyAddress,
                                }}
                              ></p>
                              <a href={url} className="block__link">
                                <div className="block__linkCog">
                                  <DownloadCog />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section sectionWhite section--close-btm">
            <div className="features">
              <div className="containerFluid">
                <div className="row">
                  <div className="col-bp1-12">
                    <Features />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

function featuredImage(imgURL, postSlug) {
  if (imgURL !== null) {
    const name = imgURL.localFile.name
    const imgRes = imgURL.localFile.childImageSharp.fluid
    return (
      <a className="block__img-link" href={postSlug}>
        <Img
          className="block__img block__img--properties"
          fluid={imgRes}
          key={name}
          alt={name}
        />
      </a>
    )
  } else {
    return (
      <a className="block__img-link" href={postSlug}>
        <Placeholder />
      </a>
    )
  }
}

export default PropertiesTemplate

export const propertiesTemplateQuery = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          databaseId
          title
          content
          parentId
          slug
          introduction {
            introTitle
            intro
          }
          forms {
            forms
          }
          properties {
            properties {
              propertyTitle
              propertyFile {
                localFile {
                  url
                }
              }
              propertyAddress
              propertyImage {
                localFile {
                  name
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
